import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import {
    Message
} from 'element-ui';

// 全局设置超时时间
axios.defaults.timeout = 15000
// 请求路由拦截
var token = sessionStorage.getItem("user_token");

axios.interceptors.request.use(function (config) {
    token = sessionStorage.getItem("user_token");
    if (config.method == 'get') {
        config.headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        }
        config.data = true
    } else {
        config.headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }
    // delete请求body传参
    if (config.method == 'delete') {
        config.data = config.body
    }
    config.headers = {
        ...config.headers,
        'Authorization': token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

var instance = axios.create({
    headers: {
        'content-type': 'multipart/form-data',
        'Authorization': token
    }
});

instance.interceptors.request.use(function (config) {
    token = sessionStorage.getItem("user_token");
    config.headers = {
        ...config.headers,
        'Authorization': token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

// respone拦截器 响应路由拦截
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status == 401) { // 没有权限
            window.sessionStorage.setItem("user_token", "");
            window.location.href = "/#/login"
        }
        return Promise.reject(error.response.status)
    }
)

export default {
    Get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(window.config.api + url, {
                params
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.data)
            })
        })
    },
    Post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(window.config.api + url, params)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Put(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.put(window.config.api + url, params)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Delete(url, data) {
        return new Promise((resolve, reject) => {
            axios.delete(window.config.api + url, { body: data })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.data)
                })
        })
    },
    Postform(url, data = {}) {
        return new Promise((resolve, reject) => {
            instance.post(window.config.api + url, data)
                .then(res => {
                    resolve(res.data);
                }, err => {
                    reject(err.data)
                })
        })
    },
    Getfile(url, fileName) {
        return new Promise((resolve, reject) => {
            axios.get(window.config.api + url, {
                responseType: 'arraybuffer'
            }).then(res => {
                if (res.headers['content-type'].indexOf('application/json') > -1) {
                    const result = JSON.parse(Buffer.from(res.data).toString('utf8'))
                    if (result.statusCode && result.statusCode != 2000) {
                        Message({
                            message: result.notification,
                            type: 'error'
                        })
                        return
                    }
                } else {
                    resolve(res.data)
                    let blob = new Blob([res.data], {
                        type: 'application/vnd.ms-excel'
                    })
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName)
                    } else {
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = fileName
                        link.click()
                        Message({
                            message: '下载成功！',
                            type: 'success'
                        })
                        //释放内存
                        window.URL.revokeObjectURL(link.href)
                    }
                }

            }).catch(err => {
                if (err) {
                    Message({
                        message: '下载失败！',
                        type: 'error'
                    })
                }
                reject(err.data)
            })
        })
    },
    Postfile(url, params = {}, fileName) {
        return new Promise((resolve, reject) => {
            axios.post(window.config.api + url,
                params,
                { responseType: 'arraybuffer' }
            ).then(res => {
                if (res.headers['content-type'].indexOf('application/json') > -1) {
                    const result = JSON.parse(Buffer.from(res.data).toString('utf8'))
                    if (result.statusCode && result.statusCode != 2000) {
                        Message({
                            message: result.notification,
                            type: 'error'
                        })
                        return
                    }
                } else {
                    resolve(res.data)
                    let blob = new Blob([res.data], {
                        type: 'application/vnd.ms-excel'
                    })
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName)
                    } else {
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = fileName
                        link.click()
                        Message({
                            message: '下载成功！',
                            type: 'success'
                        })
                        //释放内存
                        window.URL.revokeObjectURL(link.href)
                    }
                }
            }).catch(err => {
                if (err) {
                    Message({
                        message: '下载失败！',
                        type: 'error'
                    })
                }
                reject(err.data)
            })
        })
    },
    // 表单元格回调
    cellStyle({ row, column, rowIndex, columnIndex }) {
        if (column.label == "机构名称") {
            return "text-align: left !important";
        }
    },
    // 表头回调
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
        if (column.label == "机构名称") {
            return "text-align: left !important";
        }
    },
    // 过滤首尾空格
    filterSpace(Form) {
        for (var key in Form) {
            if (typeof (Form[key]) == 'string') {
                Form[key] = Form[key].trim()
            }
        }
    },
    // 寻找对应的label
    findLabel(value, arr) {
        let label = ""
        arr.forEach(item => {
            if (item.value == value) {
                label = item.label
            }
        })
        return label
    },
    // element时间修正
    fixDateTime(val) {
        return new Date(Date.parse(new Date(val)) + 8 * 3600 * 1000)
    },
    /*
     对象数组添加字段
       参数：1.待添加数组，2.待添加字段key，3.待添加value|非必须，默认为null
    */
    addFieldArr(arr, key, value = null, level = 0) {
        return arr.map(item => {
            if (item.children && item.children.length > 0) {
                item.children = this.addFieldArr(item.children, key, value, level + 1)
            }
            let obj = {
                ...item,
                level
            }
            obj[key] = value
            return obj
        })
    },
    // 循环过滤树形数组
    filterArrForKey({ arr, key, value }) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey({
                        arr: item.children,
                        key,
                        value
                    })
                }
            }
            if (item[key] != value) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    // 树状数组指定位置增加子节点（必须标识为key）
    addChildren({ arr, value, item }) {
        let newArr = []
        arr.forEach(ele => {
            if (ele.key === value) {
                ele.children.push(item)
                newArr.push(ele)
            } else {
                if (ele.children.length > 0) {
                    ele.children = this.addChildren({
                        arr: ele.children,
                        value,
                        item
                    })
                    newArr.push(ele)
                } else {
                    newArr.push(ele)
                }
            }
        })
        return newArr
    },
    //限制输入特殊字符
    btKeyUp(e) {
        let key = e.key
        // 不允许输入'e'和'.'、'+'、'-'、'Shift'
        if (key === 'e' || key === '.' || key === '+' || key === '-' || key === 'Shift') {
        e.returnValue = false
        return false
        } 
        return true
    },
}