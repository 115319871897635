import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


import './style/reset.css'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/font-awesome-4.7.0/css/font-awesome.css';
import './assets/fontawesome-pro-5.7.2-web/css/fontawesome.css';
import './assets/fontawesome-pro-5.7.2-web/css/solid.css';
import './assets/fontawesome-pro-5.7.2-web/css/regular.css';

import moment from 'moment'
Vue.prototype.$moment = moment;

import utils from './utils/utils'
Vue.prototype.$utils = utils

// 全局时间过滤器
Vue.filter('dateformat', (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
  if (dataStr == '' || dataStr == null) {
    return ''
  }
  return moment(dataStr).format(pattern)
})
// 全局状态转换
Vue.filter('toggleState', (id) => {
  let text = "--"
  if (id === 1) {
    text = "新申请"
  }
  if (id === 8) {
    text = "不同意"
  }
  if (id === 9) {
    text = "同意"
  }
  return text
})

import {
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Table,
  TableColumn,
  Pagination,
  InputNumber,
  Select,
  Option,
  DatePicker,
  Tooltip,
  Message,
  MessageBox,
  Autocomplete,
  Dialog,
  Button,
  Upload,
  Cascader,
  Divider,
  Loading,
  Tree
} from 'element-ui'
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Tooltip)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Divider)
Vue.use(Loading)
Vue.use(Tree)
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next('/login')
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
