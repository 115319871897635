<template>
  <div id="app">
    <Header />
    <div
      v-loading="Loading"
      element-loading-text="接口响应中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)"
      style="height: 100vh;"
    >
      <router-view />
    </div>
    <!-- 闸门弹出层 -->
    <div class="window gate" v-show="show1">
      <div class="window-header red">1号闸门</div>
      <div class="main">
        <div class="main-left">
          <div class="text-row">
            <div class="text-l">时间：</div>
            <div class="text-r">{{gateData.sj|dateformat}}</div>
          </div>
          <div class="text-row">
            <div class="text-l">闸门状态：</div>
            <div class="text-r">{{gateData.zmzt}}</div>
          </div>
          <div class="text-row">
            <div class="text-l">控制模式：</div>
            <div class="text-r">{{gateData.kzms}}             
            </div>            
          </div>
          <div class="text-row">
            <div class="text-l">当前开度：</div>
            <div class="text-r">{{gateData.dqkd}}</div>
          </div>
          <div class="text-row">
            <div class="text-l">当前流量：</div>
            <div class="text-r">{{gateData.dqll}}</div>
          </div>
          <div class="text-row">
            <div class="text-l">闸前水位：</div>
            <div class="text-r">{{gateData.zqsw}}</div>
          </div>
        </div>
        <div class="main-right">
          <img src="./assets/1.png" class="gate-img" alt="" />
          <div class="door" id="door"></div>
          <div class="form">
            <div class="close-btn" id="btn_change" style="margin-bottom:20px;width:100px;" @click="changeMode()">
              <i class="fa"></i>
              切换控制模式
            </div> 
            <br>           
            <span style="font-weight: bold">开度：</span>
            <el-input-number
              v-model="num"
              size="small"
              :min="0"
              :max="600"
              label="描述文字"
            ></el-input-number>
            <br /><br />
            <span style="font-weight: bold">流量：</span><el-input-number
              v-model="flowNum"
              size="small"
              :min="0"
              :max="1"
              :step="0.05"
              label="描述文字"
            ></el-input-number>
            <br /><br />
            <div class="open-btn" id="btn_open" @click="openGate()">
              <i class="fa fa-lock-open"></i>
              开启
            </div>
            <div class="close-btn" id="btn_close" @click="closeGate()">
              <i class="fa fa-lock"></i>
              关闭
            </div>
          </div>
        </div>
      </div>
      <div class="table-wrapper">
        <el-table :data="monitorData" border style="width: 100%" height="100%">
          <el-table-column prop="deviceName" label="测站名称" width="120">
          </el-table-column>
          <el-table-column prop="recordBeforeLevel" label="水深(mm)" width="120">
          </el-table-column>
          <el-table-column prop="recordInstantFlow" label="瞬时流量(m³/s)" width="140">
          </el-table-column>
            <el-table-column label="时间">
              <template slot-scope="scope">
                {{ scope.row.recordCollectTime | dateformat }}
              </template>
            </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="Monitor.pageIndex"
              :page-size="Monitor.pageSize"
              :total="Monitor.total"
              @size-change="(val) => (Monitor.pageSize = val)"
              @current-change="(val) => (Monitor.pageIndex = val)"
            >
            </el-pagination>
      </div>
    </div>

    <!-- 水文站弹出层 -->
    <div class="window" v-show="show2">
      <div class="window-header blue">1号水文站</div>
      <div class="main">
        <div class="main-left">
          <div class="text-row">
            <div class="text-l">时间：</div>
            <div class="text-r">2021-01-27 20:26:30</div>
          </div>
          <div class="text-row">
            <div class="text-l">站点状态：</div>
            <div class="text-r">正常</div>
          </div>
          <div class="text-row">
            <div class="text-l">当前水位：</div>
            <div class="text-r">1.31 m</div>
          </div>
          <div class="text-row">
            <div class="text-l">当前流速：</div>
            <div class="text-r">5 m3/s</div>
          </div>
          <div class="text-row">
            <div class="text-l">当前雨量：</div>
            <div class="text-r">1.2 mm</div>
          </div>
          <div class="text-row">
            <div class="text-l">最高水位：</div>
            <div class="text-r">4 m</div>
          </div>
        </div>
        <div class="main-right">
          <img src="./assets/t.png" class="water-img" alt="" />
        </div>
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column prop="x1" label="测站名称" width="120">
          </el-table-column>
          <el-table-column prop="x2" label="水深(mm)" width="80">
          </el-table-column>
          <el-table-column prop="x3" label="瞬时流量(m³/s)" width="140">
          </el-table-column>
          <el-table-column prop="x4" label="时间">
            <template slot-scope="scope">
              {{ scope.row.x4 | dateformat }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination background layout="total, prev, pager, next" :total="30">
        </el-pagination>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div
      class="mask"
      v-show="show1 || show2"
      @click="
        show1 = false;
        show2 = false;
      "
    ></div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { mapActions } from "vuex";
import axios from 'axios';
export default {
  components: {
    Header,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    loading(val) {
      this.Loading = val;
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      num: 0,
      flowNum:0,
      tableData: [],
      Loading: false,
      gateData:{
        kzms:null,
        sj:null,
        zmzt:null,
        dqkd:null,
        dqll:null,
        zqsw:null
      },
      Monitor: {
        time: [],
        active: 1,
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      monitorData: [],      
      operationData:{
        dispatchId: 1,
        deviceId: 20,
        openSize: 100,
        flow: 10,
        speed: 5,
        beforeLevel: 33.5,
        afterLevel: 33.7,
        waterQuantity: 3566777,
        startTime: new Date('2021-08-12 11:00:00'),
        collectTime: new Date('2021-08-12 11:00:00')
    },
      controlModel:null,   //闸门控制模式 0：远程控制；1：本地控制
      controlType:null,    //远程控制模式：0：开度模式；3：流量模式
      tempResp:{},
      mainUrl:'http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/'
    };
  },
  methods: {
    ...mapActions(["todoList", "getMyTree", "getMineInfo","AddDispatchOperation","rainList"]),
    openGate() {
      if(this.gateData.kzms=='开度控制')
          this.openGateBykdkz();
      else{
          this.openGateByLlkz()
        }
      },
    queryGateDataList() {
      let data = {
        startRecordCollectTime: null,
        endRecordCollectTime: null,
        stationId: 1,
      };
      if (this.Monitor.time.length > 0) {
        data.startRecordCollectTime = this.$utils.fixDateTime(
          this.Monitor.time[0]
        );
        data.endRecordCollectTime = this.$utils.fixDateTime(this.Monitor.time[1]);
      }
      this.rainList({
        _this: this,
        data,
      }).then((res) => {
        if (res != false) {
          this.monitorData = res.body.records;
          this.Monitor.total = res.body.recordCount;
          console.log(this.monitorData);
        }
      });
    },      
    updateOpertion(){
        // 获取所有灌域
        this.AddDispatchOperation({
            _this: this,
            data: this.$data.operationData,
          })
            .then((res) => {
              if (res !== false) {
                console.log(res);
                // res.body.records.forEach((item) => {
                //   this.IrrigationOptions.push({
                //     value: item.id,
                //     label: item.name,
                //   });
                // });
              }
            })
            .catch();
    },  
    //关闭闸门：设置开度为0
    closeGate() {
      let _this = this;
      this.num = 0;
          if(this.controlModel==1){
            //本地控制，无法远程
            this.$message.warning("闸门在本地模式下，无法远程控制！");
            return;
          }
          else 
          if(this.controlModel==0){
              //设置开度控制模式
              axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getControlMode/1538')
                  .then(resp=>{
                     let resData = resp.data;
                      if(resp.status==200){
                        if(resData.value.currentValue==0){
                          //已经是开度模式，设置闸门开度，开启闸门
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setGatePositionSetpoint/1538/1/'+this.$data.num)
                              .then(resp2=>{
                                console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status && resp2.status==200){
                                      if(resData2.resultCode==0){
                                         this.$message.warning('闸门关闭！')
                                         //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                        _this.queryGateData()
                                        _this.updateOpertion();
                                      }
                                      else{
                                        this.$message.warning('闸门远程关闭失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        //设置闸门为开度模式
                        else{
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setPositionControlMode/1538')
                              .then(resp2=>{
                                // console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status==200){
                                      if(resData2.resultCode==0){
                                        axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setGatePositionSetpoint/1538/1/'+this.$data.num)
                                            .then(resp3=>{
                                              // console.log(resp2.data);
                                                let resData3 = resp3.data;
                                                if(resp3.status==200){
                                                    if(resData3.resultCode==0){
                                                      this.$message.warning('闸门远程关闭成功！')
                                                      //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                                      setInterval(function(){_this.queryGateData();_this.queryGateDataList()},5000);
                                                    }
                                                    else{
                                                      this.$message.warning('闸门远程关闭失败！原因：'+resData3.message)
                                                    }
                                                }
                                            })
                                      }
                                      else{
                                        this.$message.warning('开度控制模式设置失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        // this.controlModel= resData.value.currentValue;
                      }
                  })
          }   
      //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
    },    
    openGateBykdkz() {
      let _this = this;
      if (this.num == 0) {
        this.$message.warning("请设置开度！");
        return;
      }
      else{
          if(this.controlModel==1){
            //本地控制，无法远程
            this.$message.warning("闸门在本地模式下，无法远程打开！");
            return;
          }
          else 
          if(this.controlModel==0){
              //设置开度控制模式
              axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getControlMode/1538')
                  .then(resp=>{
                     let resData = resp.data;
                      if(resp.status==200){
                        if(resData.value.currentValue==0){
                          //已经是开度模式，设置闸门开度，开启闸门
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setGatePositionSetpoint/1538/1/'+this.$data.num)
                              .then(resp2=>{
                                console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status && resp2.status==200){
                                      if(resData2.resultCode==0){
                                         this.$message.warning('闸门远程开启成功！')
                                         //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                        setInterval(function(){_this.queryGateData();_this.queryGateDataList()},5000);                                      }
                                      else{
                                        this.$message.warning('闸门远程开启失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        //设置闸门为开度模式
                        else{
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setPositionControlMode/1538')
                              .then(resp2=>{
                                // console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status==200){
                                      if(resData2.resultCode==0){
                                        axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setGatePositionSetpoint/1538/1/'+this.$data.num)
                                            .then(resp3=>{
                                              // console.log(resp2.data);
                                                let resData3 = resp3.data;
                                                if(resp3.status==200){
                                                    if(resData3.resultCode==0){
                                                      this.$message.warning('闸门远程开启成功！')
                                                      //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                                      setInterval(function(){_this.queryGateData();_this.queryGateDataList();},5000);
                                                    }
                                                    else{
                                                      this.$message.warning('闸门远程开启失败！原因：'+resData3.message)
                                                    }
                                                }
                                            })
                                      }
                                      else{
                                        this.$message.warning('开度控制模式设置失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        // this.controlModel= resData.value.currentValue;
                      }
                  })
          }

      }
    },
    //流量控制模式
    openGateByLlkz(){
      let _this = this;
        if (this.flowNum == 0) {
          this.$message.warning("请设置流量！");
          return;
      }
      else{
          if(this.controlModel==1){
            //本地控制，无法远程
            this.$message.warning("闸门在本地模式下，无法远程打开！");
            return;
          }
          else 
          if(this.controlModel==0){
              //设置流量控制模式
              axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getControlMode/1538')
                  .then(resp=>{
                     let resData = resp.data;
                      if(resp.status==200){
                        if(resData.value.currentValue==3){
                          //已经是开度模式，设置闸门开度，开启闸门
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setFlowSetpoint/1538/'+this.$data.flowNum)
                              .then(resp2=>{
                                console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status && resp2.status==200){
                                      if(resData2.resultCode==0){
                                         this.$message.warning('闸门远程开启成功！')
                                         //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                         setInterval(function(){_this.queryGateData();_this.queryGateDataList();},5000);
                                      }
                                      else{
                                        this.$message.warning('闸门远程开启失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        //设置闸门为流量模式
                        else{
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setFlowControlMode/1538')
                              .then(resp2=>{
                                // console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status==200){
                                      if(resData2.resultCode==0){
                                        axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setFlowSetpoint/1538/'+this.$data.flowNum)
                                            .then(resp3=>{
                                              // console.log(resp2.data);
                                                let resData3 = resp3.data;
                                                if(resp3.status==200){
                                                    if(resData3.resultCode==0){
                                                      this.$message.warning('闸门远程开启成功！')
                                                      setInterval(function(){_this.queryGateData();_this.queryGateDataList();},5000);
                                                      //document.getElementById("door").style.height = 30 - this.num / 20 + "px";
                                                    }
                                                    else{
                                                      this.$message.warning('闸门远程开启失败！原因：'+resData3.message)
                                                    }
                                                }
                                            })
                                      }
                                      else{
                                        this.$message.warning('开度控制模式设置失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        // this.controlModel= resData.value.currentValue;
                      }
                  })
          }

      }
    },
    changeToKdkz(){
      let _this = this;
          if(this.controlModel==1){
            //本地控制，无法远程
            this.$message.warning("闸门在本地模式下，无法远程控制！");
            return;
          }
          else 
          if(this.controlModel==0){
              //设置开度控制模式
              axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getControlMode/1538')
                  .then(resp=>{
                     let resData = resp.data;
                      if(resp.status==200){
                        if(resData.value.currentValue==0){
						              	_this.queryGateData();
                        }
                        //设置闸门为开度模式
                        else{
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setPositionControlMode/1538')
                              .then(resp2=>{
                                // console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status==200){
                                      if(resData2.resultCode==0){
                                        _this.queryGateData();
                                        this.$message.warning('已切换至开度控制模式');
                                      }
                                      else{
                                        this.$message.warning('开度控制模式设置失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        // this.controlModel= resData.value.currentValue;
                      }
                  })
          }
    },    
    changeToLlkz(){
      let _this = this;
          if(this.controlModel==1){
            //本地控制，无法远程
            this.$message.warning("闸门在本地模式下，无法远程控制！");
            return;
          }
          else 
          if(this.controlModel==0){
              //设置流量控制模式
              axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getControlMode/1538')
                  .then(resp=>{
                     let resData = resp.data;
                      if(resp.status==200){
                        if(resData.value.currentValue==3){
						              	_this.queryGateData();
                        }
                        //设置闸门为流量模式
                        else{
                          axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/setFlowControlMode/1538')
                              .then(resp2=>{
                                // console.log(resp2.data);
                                  let resData2 = resp2.data;
                                  if(resp2.status==200){
                                      if(resData2.resultCode==0){
                                        _this.queryGateData();
                                        this.$message.warning('已切换至流量控制模式');
                                      }
                                      else{
                                        this.$message.warning('流量控制模式设置失败！原因：'+resData2.message)
                                      }
                                  }
                              })
                        }
                        // this.controlModel= resData.value.currentValue;
                      }
                  })
          }
    },
    changeMode(){
        if(this.gateData.kzms=='开度控制')
          this.changeToLlkz();
        else{
          this.changeToKdkz();
        }
    },
    queryGateData(){
      axios.get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getSiteRegisterList/1538/0')
           .then(response=>{
              if(response.status==200){
                // alert(1);
                let resData = response.data;
                if(resData.resultCode==0){
                    resData.value.forEach(item => {
                      switch(item.registerName){
                        case 'G1_LOC_REM':  this.$data.controlModel = item.currentValue;
                        case 'COMM_STATUS':  this.$data.gateData.sj = item.updateTime;break;
                        case 'CTRL_MODE':this.$data.gateData.kzms = item.currentValue==0?'开度控制':'流量控制';break;
                        case 'G1_POS_VAL':
                            this.$data.gateData.dqkd = item.currentValue;
                            this.$data.gateData.zmzt=item.currentValue>0?'开':'关';
                            //debugger;
                            if(item.currentValue>0){
                                //this.$data.num = item.currentValue;   
                                document.getElementById("door").style.height = 30 - item.currentValue / 20 + "px";
                            }   
                            break;
                        case 'USL_VAL':this.$data.gateData.zqsw = item.currentValue;break;
                        case 'FLOW_VAL':this.$data.gateData.dqll = item.currentValue;break;
                    }
                    });
                }
                else{
                  this.$message.warning('请求闸门状态异常！');
                  return;
                }
              }
    }
          ).catch(function (error) { // 请求失败处理
            console.log(error);
          });
          // return gateDatatemp;
    }
  },
  mounted() {
    this.$store.commit("interValNow");
    let _this = this;
    let time =Date.parse(new Date(this.now)) - 60 * 60 * 24 * 7 * 1000 - 60 * 60 * 3;
    // let time =new Date('2021-08-10 00:00:00');
    let date = this.$options.filters["dateformat"](time, "YYYY-MM-DD");
    this.Monitor.time.push(date, this.now);
    //this.Water.time.push(date, this.now);
    window.openWindow = (type) => {
      if (type == 1) {
        _this.show1 = true;
      }
      if (type == 2) {
        _this.show2 = true;
      }
    };
    if (window.sessionStorage.getItem("user_token")) {
      // 初始化数据
      this.todoList({
        _this: this,
      })
        .then((res) => {
          if (res !== false) {
            this.$store.commit("saveTodoCount", res.body.recordCount);
          }
       _this.queryGateData();
       _this.queryGateDataList();
        })
        .catch();
      this.getMyTree(this)
        .then((res) => {
          if (res !== false) {
            this.$store.commit("saveMyTree", res.body);
          }
        })
        .catch();
      this.getMineInfo(this)
        .then((res) => {
          if (res !== false) {
            this.$store.commit("saveMyInfo", res.body);
          }
        })
        .catch();
    }
  }
};
</script>

<style lang="less">
#app {
  color: #666;
  font-size: 14px;
}
.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 98;
}
.red {
  color: rgb(217, 0, 27);
}
.blue {
  color: rgb(0, 185, 231);
}
.grey {
  color: rgb(170, 170, 170);
}
.link {
  color: #409bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.star {
  color: #f00;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.page {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 80px);
  left: 0;
  top: 80px;
  z-index: 5;
  background-color: #fff;
  overflow: auto;
}
// 覆盖物
.marker-div {
  height: 32px;
  width: 100px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  &.red {
    background-color: rgba(217, 0, 27, 0.8);
    &:hover {
      background-color: rgba(217, 0, 27, 1);
      box-shadow: 0 0 10px rgb(217, 0, 27);
    }
  }
  &.blue {
    background-color: rgba(0, 185, 231, 0.8);
    &:hover {
      background-color: rgba(0, 185, 231, 1);
      box-shadow: 0 0 10px rgb(0, 185, 231);
    }
  }
  &.grey {
    background-color: rgba(170, 170, 170, 0.8);
    &:hover {
      background-color: rgba(170, 170, 170, 1);
      box-shadow: 0 0 10px rgb(170, 170, 170);
    }
  }
}
// 首页弹窗
.window {
  width: 60%;
  min-width: 800px;
  height: 80%;
  min-height: 500px;
  background-color: #fff;
  position: absolute;
  left: 20%;
  top: 100px;
  z-index: 999;
  padding: 20px;
  .window-header {
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    font-size: 18px;
  }
  .main {
    margin: 20px 0;
    > div {
      display: inline-block;
      vertical-align: top;
    }
    .main-left {
      width: 240px;
      .text-row {
        margin-bottom: 15px;
        > div {
          display: inline-block;
          vertical-align: top;
        }
        .text-l {
          width: 80px;
          font-weight: bold;
          text-align: right;
        }
        .text-r {
          width: 160px;
        }
      }
    }
    .main-right {
      width: calc(100% - 240px);
      height: 250.6px;
      position: relative;
      .gate-img {
        position: absolute;
        right: 180px;
        top: 0;
        transform: scale(0.8);
      }
      .water-img {
        position: absolute;
        right: 50px;
        top: 0;
        width: 500px;
      }
      .door {
        width: 120px;
        height: 40px;
        position: absolute;
        right: 293px;
        top: 86px;
        z-index: 9;
        background: url("./assets/2.png");
        transition: height 1s linear;
      }
      .form {
        position: absolute;
        right: 10px;
        bottom: 20px;
        .open-btn,
        .close-btn {
          display: inline-block;
          width: 80px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          background-color: rgba(0, 185, 231, 1);
        }
        .open-btn {
          background-color: rgb(217, 0, 27);
          margin-right: 20px;
        }
      }
    }
  }
  .table-wrapper {
    height: calc(100% - 325px);
    margin-top: -20px;
  }
}
// 用水表单专用
.apply-body {
  width: 1000px;
  margin: 20px auto;
  .apply-header {
    color: rgb(0, 185, 231);
    border-bottom: 1px solid #ddd;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
  }
  .row {
    display: flex;
    margin-top: 10px;
    .search-item {
      flex: 1;
      margin-bottom: 10px;
      height: 40px;
      line-height: 40px;
      div {
        display: inline-block;
      }
      .label {
        color: #333;
        font-weight: 600;
        width: 140px;
        text-align: right;
        vertical-align: top;
      }
      .content {
        width: calc(100% - 140px);
        position: relative;
        .content-footer {
          position: absolute;
          width: 60px;
          height: 32px;
          line-height: 32px;
          font-weight: bold;
          text-align: center;
          right: 0px;
          top: 5px;
          border-radius: 3px;
          background-color: rgb(220, 223, 230);
          color: #333;
        }
      }
    }
  }
  .btn-list {
    margin-top: 100px;
    text-align: center;
    .btn {
      margin-right: 10px;
    }
  }
}
// 列表页专属样式
.page-main {
  padding: 0 20px;
}
.search-div {
  height: 60px;
  margin-top: 10px;
  display: flex;
  border-bottom: 1px solid #ddd;
  min-width: 500px;
  .search-item {
    flex: 1;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
    div {
      display: inline-block;
    }
    .label {
      color: #333;
      font-weight: 600;
      width: 80px;
      text-align: right;
    }
    .content {
      width: calc(100% - 80px);
    }
  }
}
.btn-div {
  padding: 20px 0;
}
// 详情页特殊样式
.detail {
  .content {
    color: #777;
  }
  .apply-main {
    padding: 20px 0;
  }
  .search-item {
    margin-bottom: 0 !important;
  }
  .row {
    margin-top: 0px;
  }
}
// element-ui样式修改
.el-input__inner {
  height: 32px !important;
}
.el-range-input {
  transform: translateY(-2px);
}
.el-date-editor--date .el-input__inner {
  padding-right: 0;
}
.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--datetime {
  margin-right: -20px !important;
}
.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
  margin-right: -20px !important;
}
.el-date-editor.el-range-editor.el-input__inner {
  line-height: 32px !important;
}
.el-table__header tr,
.el-table__header th {
  padding: 0 !important;
  height: 40px !important;
  background-color: rgb(0, 185, 231) !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 14px !important;
}
.gate .el-table__header tr,
.gate .el-table__header th {
  background-color: rgb(217, 0, 27) !important;
}
.el-table__body tr,
.el-table__body td {
  padding: 0 !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 14px !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(0, 185, 231) !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: transparent !important;
}
/* 处理input type = number的上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
// 处理ie输入框多余叉叉符号
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.el-select,
.el-date-editor,
.el-autocomplete,
.el-cascader {
  width: 100% !important;
}
.itemBar .el-input__inner {
  padding-left: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.el-autocomplete-suggestion.el-popper {
  min-width: 200px !important;
}
</style>
