import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/monitor',
    name: 'Monitor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Monitor/Monitor.vue')
  },
  {
    path: '/todo',
    name: 'Todo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Todo/Page.vue')
  },
  {
    path: '/monitor/:id',
    name: 'MonitorDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Monitor/Detail.vue')
  },
  {
    path: '/picture',
    name: 'Picture',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Monitor/Picture.vue')
  },
  {
    path: '/water/water-apply/page',
    name: 'WaterApplyPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterApply/Page.vue')
  },
  {
    path: '/water/water-apply/apply',
    name: 'WaterApply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterApply/Apply.vue')
  },
  {
    path: '/water/water-apply/detail/:id',
    name: 'WaterDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterApply/Detail.vue')
  },
  {
    path: '/water/water-apply/approval/:id',
    name: 'WaterApproval',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterApply/Approval.vue')
  },
  {
    path: '/water/water-user/page',
    name: 'WaterUserPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterUser/Page.vue')
  },
  {
    path: '/water/water-user/detail/:id',
    name: 'WaterUserDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterUser/Detail.vue')
  },
  {
    path: '/water/water-user/approval/:id',
    name: 'WaterUserApproval',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/WaterUser/Approval.vue')
  },
  {
    path: '/water/transaction/page',
    name: 'TransactionPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/Transaction/Page.vue')
  },
  {
    path: '/water/transaction/add',
    name: 'AddTransaction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/Transaction/Add.vue')
  },
  {
    path: '/water/transaction/detail/:id',
    name: 'TransactionDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/Transaction/Detail.vue')
  },
  {
    path: '/water/transaction/edit/:id',
    name: 'EditTransaction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Water/Transaction/Edit.vue')
  },
  {
    path: '/statistics/water',
    name: 'StatisticsWater',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Statistics/Water.vue')
  },
  {
    path: '/statistics/gate',
    name: 'StatisticsGate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Statistics/Gate.vue')
  },
  {
    path: '/basic/Irrigation/page',
    name: 'IrrigationPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Irrigation/Page.vue')
  },
  {
    path: '/basic/organization',
    name: 'Organization',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Organization/Page.vue')
  },
  {
    path: '/basic/Irrigation/add',
    name: 'IrrigationAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Irrigation/Add.vue')
  },
  {
    path: '/basic/Irrigation/detail/:id',
    name: 'IrrigationDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Irrigation/Detail.vue')
  },
  {
    path: '/basic/Irrigation/edit/:id',
    name: 'IrrigationEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Irrigation/Edit.vue')
  },
  {
    path: '/basic/channel/page',
    name: 'ChannelPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Channel/Page.vue')
  },
  {
    path: '/basic/channel/add',
    name: 'ChannelAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Channel/Add.vue')
  },
  {
    path: '/basic/channel/detail/:id',
    name: 'ChannelDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Channel/Detail.vue')
  },
  {
    path: '/basic/channel/edit/:id',
    name: 'ChannelEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Channel/Edit.vue')
  },
  {
    path: '/basic/station/page',
    name: 'StationPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Station/Page.vue')
  },
  {
    path: '/basic/station/add',
    name: 'StationAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Station/Add.vue')
  },
  {
    path: '/basic/station/detail/:id',
    name: 'StationDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Station/Detail.vue')
  },
  {
    path: '/basic/station/edit/:id',
    name: 'StationEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Station/Edit.vue')
  },
  {
    path: '/basic/gate/page',
    name: 'GatePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Gate/Page.vue')
  },
  {
    path: '/basic/gate/add',
    name: 'GateAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Gate/Add.vue')
  },
  {
    path: '/basic/gate/edit/:id',
    name: 'GateEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Gate/Edit.vue')
  },
  {
    path: '/basic/gate/detail/:id',
    name: 'GateDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Gate/Detail.vue')
  },
  {
    path: '/basic/water/page',
    name: 'WaterPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Water/Page.vue')
  },
  {
    path: '/basic/water/add',
    name: 'WaterAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Water/Add.vue')
  },
  {
    path: '/basic/water/edit/:id',
    name: 'WaterEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Water/Edit.vue')
  },
  {
    path: '/basic/water/detail/:id',
    name: 'WaterDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Basic/Water/Detail.vue')
  },
  {
    path: '/system/user/page',
    name: 'UserPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/User/Page.vue')
  },
  {
    path: '/system/user/add',
    name: 'UserAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/User/Add.vue')
  },
  {
    path: '/system/user/edit/:id',
    name: 'UserEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/User/Edit.vue')
  },
  {
    path: '/system/role/page',
    name: 'RolePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Role/Page.vue')
  },
  {
    path: '/system/role/add',
    name: 'AddRole',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Role/Add.vue')
  },
  {
    path: '/system/role/edit/:id',
    name: 'EditRole',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Role/Edit.vue')
  },
  {
    path: '/system/dictionary',
    name: 'Dictionary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Dictionary/Page.vue')
  },
  {
    path: '/system/dictionary/edit/:type',
    name: 'DictionaryEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Dictionary/Edit.vue')
  },
  {
    path: '/system/params/page',
    name: 'ParamsPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Params/Page.vue')
  },
  {
    path: '/system/params/edit/:id',
    name: 'EditParams',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/System/Params/Edit.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
