<template>
  <div class="header">
    <div class="header-l">
      <img src="../assets/logo2.png" class="logo" width="50" alt="" />
      闸门测控一体化管理平台
    </div>
    <div class="header-r">
      <div class="menu-list" :key="Menu.length">
        <div
          class="menu-item"
          v-for="(item, index) in Menu"
          :key="index"
          @mouseenter="mouseEnter(index)"
          @mouseleave="mouseLeave(index)"
          @click="handleClick(item.route)"
        >
          <i :class="item.icon" style="margin-right: 5px"></i>{{ item.text }}
          <div
            class="children-menu"
            v-if="item.children != null"
            v-show="showChildren[index]"
          >
            <div
              class="children-item"
              v-for="(ele, i) in item.children"
              :key="i"
              @click="handleClick(ele.route)"
            >
              <i
                :class="ele.icon"
                style="margin-right: 5px; margin-left: 20px"
              ></i
              >{{ ele.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info">
      <i class="fa fa-user-alt user-icon" @click="goTodo()"></i>
      <span class="msg-count" v-if="todoCount > 0">{{ todoCount }}</span>
      <span class="user-name" :title="myInfo.name">{{myInfo.name}}</span>
      <span class="logout-btn" @click="logout()">退出</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      showChildren: [false, false, false, false, false, false],
      count: 0,
    };
  },
  computed: {
    ...mapState(["todoCount", "Menu", "myInfo"])
  },
  methods: {
    ...mapActions(["todoList"]),
    mouseEnter(index) {
      this.$set(this.showChildren, index, true);
    },
    mouseLeave(index) {
      this.$set(this.showChildren, index, false);
    },
    handleClick(path) {
      if (path && path != null && path != this.$route.path) {
        this.$router.push(path);
      }
    },
    logout() {
      window.sessionStorage.setItem("user_token", "");
      this.$router.push("/login");
    },
    goTodo() {
      if (this.todoCount > 0 && this.$route.path != "/todo") {
        this.$router.push('/todo')
      }
    }
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100vw;
  height: 80px;
  background: url("../assets/header-img.png");
  background-size: 100vw 80px;
  color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .header-l {
    width: calc(100% - 900px);
    height: 80px;
    display: inline-block;
    font-size: 28px;
    font-weight: 700;
    line-height: 80px;
    color: #fff;
    padding-left: 90px;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    .logo {
      position: absolute;
      left: 20px;
      top: 15px;
    }
  }
  .header-r {
    width: 700px;
    height: 40px;
    display: inline-block;
    .menu-list {
      width: 700px;
      height: 40px;
      line-height: 40px;
      display: flex;
      transform: translateY(40px);
      .menu-item {
        flex: 1;
        cursor: pointer;
        font-size: 18px;
        text-align: center;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
        .children-menu {
          width: 120%;
          transform: translateX(-10%);
          box-shadow: 0 0 3px #ccc;
          text-align: left;
          font-size: 16px;
          line-height: 40px;
          background-color: #fff;
          .children-item {
            color: rgba(45, 45, 45, 0.6);
            border-bottom: 1px solid #ccc;
            &:hover {
              color: rgba(45, 45, 45, 0.8);
            }
          }
        }
      }
    }
  }
  .user-info {
    width: 200px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    .user-icon {
      font-size: 40px;
      position: absolute;
      left: 60px;
      top: 20px;
      cursor: pointer;
    }
    .msg-count {
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 56px;
      top: 14px;
      background-color: red;
    }
    .user-name {
      font-size: 18px;
      position: absolute;
      left: 120px;
      top: 15px;
      width: 80px;
      white-space: nowrap;/*强制在一行显示*/
      text-overflow:ellipsis;/*设置超出内容显示...*/
      overflow: hidden;/*一定不能少 超出的内容进行隐藏*/
    }
    .logout-btn {
      font-size: 16px;
      position: absolute;
      left: 120px;
      top: 45px;
      cursor: pointer;
    }
  }
}
</style>