import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    now: "",
    todoCount: 0,
    loading: false,
    allOptions: [
      {
        type: "ControlType",
        arr: [
          {
            value: 1,
            label: "流量控制"
          },
          {
            value: 2,
            label: "开度控制"
          }
        ]
      },
      {
        type: "DeviceState",
        arr: [
          {
            value: 1,
            label: "在用良好"
          },
          {
            value: 2,
            label: "在用故障"
          },
          {
            value: 3,
            label: "停用"
          }
        ]
      }
    ],
    Menu: [],
    myInfo: {}
  },
  mutations: {
    // 定时任务
    interValNow() {
      if (this.state.now == "") {
        this.state.now = new Date()
      }
      let myDate = new Date(this.state.now);
      let datetime = new Date(myDate.getTime() + 1000)
      this.state.now = datetime
      setTimeout(() => {
        this.commit("interValNow")
      }, 1000);
    },
    // 保存待办数量
    saveTodoCount(state, count) {
      this.state.todoCount = count;
    },
    // 保存权限树
    saveMyTree(state, menu) {
      this.state.Menu = menu;
    },
    // 保存权限树
    saveMyInfo(state, obj) {
      this.state.myInfo = obj;
    }
  },
  actions: {
    // 账号密码登录
    async Login(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/auth/classic', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 行政区划树
    async getReginTree(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/standard/region/tree', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 首页
    // 监控列表
    async monitorList(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/monitor/device-record/view/first/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 前10柱状图
    async topTen(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/map/top/10', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 待办事项
    async todoList(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/common/backlog/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 上传附件
    async upload(state, {_this, file}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Postform('/v1/common/attachment/upload', file).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 下载附件
    async download(state, {_this, id, filename}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Getfile('/v1/common/attachment/' + id, filename).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 实时监测
    // 返回测站水雨情统计表[折线图表]
    async rainChart(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/monitor/device-record/rainfall/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水雨情-分页
    // async rainList(state, {_this, id, data}) {
    //   return new Promise((resolve, reject) => {
    //     this.state.loading = true
    //     _this.$utils.Post('/v1/monitor/device-record//rainfall/page/' + id, data).then(res => {
    //       debugger;
    //       this.state.now = res.now
    //       this.state.loading = false
    //       if (res.statusCode == 2000) {
    //         resolve(res)
    //       } else {
    //         _this.$message.error(res.notification);
    //         resolve(false)
    //       }
    //     }).catch((err) => {
    //       reject('出错了！错误是' + err)
    //       this.state.loading = false
    //     })
    //   })
    // },
    async rainList(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/monitor/device-record/view/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 返回测站用水量统计表[折线图表]
    async waterChart(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/monitor/device-record/usage/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水量-分页
    async waterList(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/monitor/device-record/usage/page/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },


    // 用水管理
    // 用水申请-分页
    async WaterApplyPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/usage/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水申请-新增
    async AddWaterApply(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/usage/apply', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水申请-详情
    async WaterApplyDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/water/usage/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水申请-通过uid查详情
    async WaterApplyDetailByUid(state, {_this, uid}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/water/usage/uid/' + uid).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水申请-审批
    async ApprovalWaterApply(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/usage/approval/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水户申请-分页
    async WaterUserPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/user/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水户申请-详情
    async WaterUserDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/water/user/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水户申请-通过uid查详情
    async WaterUserDetailByUid(state, {_this, uid}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/water/user/uid/' + uid).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水户申请-审批
    async ApprovalWaterUser(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/user/approval/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水权交易-分页
    async TransactionPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/transaction/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水权交易-添加
    async AddTransaction(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/water/transaction', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水权交易-详情
    async TransactionDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/water/transaction/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水权交易-编辑
    async EditTransaction(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/water/transaction/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },


    // 基础信息
    // 灌域--新增
    async AddIrrigation(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/irrigation-district', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 灌域--分页
    async IrrigationPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/irrigation-district/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 灌域--删除
    async DelIrrigation(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/base/irrigation-district', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 灌域--详情
    async IrrigationDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/irrigation-district/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 灌域--编辑
    async EditIrrigation(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/irrigation-district/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 渠道--新增
    async AddChannel(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/canal', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 渠道--分页
    async ChannelPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/canal/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 渠道--删除
    async DelChannel(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/base/canal', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 渠道--详情
    async ChannelDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/canal/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 渠道--编辑
    async EditChannel(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/canal/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 闸站--新增
    async AddGate(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/gate', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 闸站--删除
    async DelGate(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/base/station/gate', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 闸站--详情
    async GateDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/station/gate/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 闸站--编辑
    async EditGate(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/station/gate/'+ id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 测站--新增
    async AddStation(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/hydrological' , data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 测站--分页
    async StationPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 测站--删除
    async DelStation(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/base/station/hydrological', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 测站--详情
    async StationDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/station/hydrological/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 测站--编辑
    async EditStation(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/station/hydrological/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 设备--新增
    async AddDevice(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/device', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 设备--分页
    async DevicePage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/device/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 设备--详情
    async DeviceDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/station/device/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 设备--编辑
    async EditDevice(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/station/device/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 设备--所有
    async AllDevice(state, _this) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/station/device/list', {
          station: {
            type: 1
          }
        }).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 引用水计划--新增
    async AddWater(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/water-plan', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 引用水计划--分页
    async WaterPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/base/water-plan/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 引用水计划--删除
    async DelWater(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/base/water-plan', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 引用水计划--详情
    async WaterDetail(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/base/water-plan/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 引用水计划--编辑
    async EditWater(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/base/water-plan/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 组织机构--分页
    async OrganizationPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/system/organization/page', {
          ...data,
          pageIndex: 1,
          pageSize: 999
        }).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 组织机构--新增
    async AddOrganization(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/system/organization', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 组织机构--编辑
    async EditOrganization(state, {_this, data, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/system/organization/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 组织机构--删除
    async delOrganization(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/system/organization', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },

    // 系统管理
    // 系统管理-用户分页
    async getUserPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/user/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-用户新增
    async addUser(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/user', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-用户详情
    async getUser(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v2/system/user/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-用户编辑
    async editUser(state, {_this, data, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v2/system/user/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-重置密码
    async resetPassword(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v2/system/user/admin-change-password', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-用户删除
    async delUser(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Delete('/v1/system/user', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 获取完整权限树
    async getFullTree(state, _this) {
      return new Promise((resolve, reject) => {
        _this.$utils.Get('/v2/system/role/facade-tree/ALL').then(res => {
          this.state.now = res.now
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 我的信息
    async getMineInfo(state, _this) {
      return new Promise((resolve, reject) => {
        _this.$utils.Get('/v1/system/user/mine').then(res => {
          this.state.now = res.now
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 获取当前用户权限树
    async getMyTree(state, _this) {
      return new Promise((resolve, reject) => {
        _this.$utils.Get('/v2/system/role/facade-tree/PC/my').then(res => {
          this.state.now = res.now
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
        })
      })
    },
    // 系统管理-角色-分页
    async getRolePage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/role/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 系统管理-角色-新增
    async addRole(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/role', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 系统管理-角色-修改
    async editRole(state, {_this, data, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v2/system/role/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 系统管理-角色-变更启用状态
    async changeRoleState(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v2/system/role/enable/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 系统管理-角色-详情
    async detailRole(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v2/system/role/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 获取数据字典-分页
    async getDictionaryPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/options/type/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 获取数据字典-详情
    async getDictionaryForType(state, {_this, type}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/options/type', {type}).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            let arr = []
            res.body.options.forEach(item => {
              arr.push({
                value: item.id,
                label: item.name,
                type
              })
            })
            this.state.allOptions.push({
              type,
              arr
            })
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
          resolve(res)
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 获取数据字典-编辑
    async editDictionary(state, {_this, type, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v2/system/options/type/' + type, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 获取指定类型数据字典
    async getOptions(state, {_this, type}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v2/system/options/type', {type}).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 参数设置-分页
    async getParamsPage(state, {_this, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/system/properties/page', data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 参数设置-详情
    async getParams(state, {_this, name}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/system/properties?name=' + name).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 参数设置-编辑
    async editParams(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Put('/v1/system/properties/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 统计分析
    // 闸门统计-返回按月统计信息[柱状图表]
    async gateMonth(state, {_this, id}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/statistics/gate/usage/month/' + id).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 闸门统计-返回同比统计信息[柱状图表]
    async gateYear(state, {_this, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Post('/v1/statistics/gate/usage/year-over-year/' + id, data).then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水情统计-返回统计分页列表
    async StatisticsWaterPage(state, _this) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Get('/v1/statistics/water/page').then(res => {
          this.state.now = res.now
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水情统计-导出
    async exportExcel(state, {_this, filename}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Getfile('/v1/statistics/water/export', filename).then(res => {
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 水雨情-导出
    async exportRainTable(state, {_this, filename, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Postfile('/v1/monitor/device-record/rainfall/export/' + id, data, filename).then(res => {
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
    // 用水量-导出
    async exportWaterTable(state, {_this, filename, id, data}) {
      return new Promise((resolve, reject) => {
        this.state.loading = true
        _this.$utils.Postfile('/v1/monitor/device-record/usage/export/' + id, data, filename).then(res => {
          this.state.loading = false
          if (res.statusCode == 2000) {
            resolve(res)
          } else {
            _this.$message.error(res.notification);
            resolve(false)
          }
        }).catch((err) => {
          reject('出错了！错误是' + err)
          this.state.loading = false
        })
      })
    },
  // 用水管理--添加闸门操作记录
  async AddDispatchOperation(state, {_this, data}) {
    return new Promise((resolve, reject) => {
      this.state.loading = true
      _this.$utils.Post('/v1/water/usage-dispatch-operation', data).then(res => {
        debugger;
        this.state.now = res.now
        this.state.loading = false
        if (res.statusCode == 2000) {
          resolve(res)
        } else {
          _this.$message.error(res.notification);
          resolve(false)
        }
      }).catch((err) => {
        reject('出错了！错误是' + err)
        this.state.loading = false
      })
    })
  },
  },
  modules: {
  }
})
